import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const Contact = () => {
  const siteTitle = 'Onodera Ryo | contact'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <h1 class="name2">Contact</h1>
        <p class="own_desc">ご連絡は下記メールアドレスよりお願い致します。<br></br>ryo.forwhat@gmail.com</p>
        <a href="https://twitter.com/ryooo_onodera" class="own_desc2 button_slide">- twitter</a><br></br>
        <a href="https://www.instagram.com/onodera_ryo/" class="own_desc2 button_slide">- instagram</a><br></br>
        <a href="https://note.com/ryoonodera" class="own_desc2 button_slide">- note</a>
      </div>
    </Layout>
  )
}

export default Contact